import { render, staticRenderFns } from "./item-child.vue?vue&type=template&id=8d373d08&scoped=true"
import script from "./item-child.vue?vue&type=script&lang=js"
export * from "./item-child.vue?vue&type=script&lang=js"
import style0 from "./item-child.vue?vue&type=style&index=0&id=8d373d08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d373d08",
  null
  
)

export default component.exports